<template>
    <div class="permission">
         <el-dialog :visible="dialogVisiblePermission" width="20%" height="20%" :show-close="type === 0" class="dialog_content" :class="type == 1 ? 'dialog_content_1':''" @close="toggleDialog">
          <div v-if="type === 0" style="text-align: center;">
            <div style="text-align:center" v-if="typeDialog == 'Includ' "><img src="@/assets/pc/images/public/includ-success.png" alt=""></div>
            <div style="text-align:center" v-else-if="typeDialog == 'Subject' "><img src="@/assets/pc/images/public/subject.png" alt=""></div>
            <div style="text-align:center" v-else-if="typeDialog == 'Login' "><img src="@/assets/pc/images/public/hint_login.png" alt=""></div>
            <div style="text-align:center" v-else><img src="@/assets/pc/images/public/vip-toolip.png" alt=""></div>
            <div style="text-align:center;margin-top:16px;margin-bottom:5px" class="dialog_text_color">
                {{typeDialog == 'Includ'?'纳入计划成功':typeDialog == 'Subject'?'请先绑定主体':typeDialog == 'Login'?'请先登录账号':'您当前还不是协会会员'}}
            </div>
            <div style="text-align:center" class="dialog_text_color">
                {{typeDialog == 'Includ'?'您可到申报管家-申报计划中查看':typeDialog == 'Subject'?'以便查询更多政策资讯':typeDialog == 'Login'?'以便查阅更多政策资讯':''}}
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handlePermission">
                    {{typeDialog == 'Includ'?'我知道了':typeDialog == 'Subject'?'立即绑定':typeDialog == 'Login'?'去登录':'联系客服'}}
                </el-button>
            </span>
          </div>
          <div v-if="type == 1">
            <vipPermission :vipPermissionVisible="true"></vipPermission>
          </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
  data () {
    return {
      type: 0
    };
  },
  props: {
    dialogVisiblePermission: {
      type: Boolean,
      default: false
    },
    typeDialog: String
  },
  methods: {
    handlePermission () {
      this.$emit('handlePermission', this.typeDialog);
    },
    toggleDialog () {
      this.$emit('handleToggle');
    }
  }
};
</script>

<style lang="less" scoped>
.dialog_content{
  /deep/.el-dialog__footer{
    text-align: center;
  }

  /deep/.el-button{
    width: 60%;
    height: 36px;
    border-radius: 3px;
    background-color: rgba(48, 120, 240, 100);
    color: rgba(255, 255, 255, 100);
    font-size: 14px;
    text-align: center;
    font-family: Microsoft Yahei;
  }
}

.dialog_content_1{
  /deep/.el-dialog{
    box-shadow: none;
  }
  /deep/.el-dialog__body{
    background-color: rgba(0, 0, 0, .5) ;
    padding: 0 ;
  }
  /deep/.el-dialog__header{
    background-color: rgba(0, 0, 0, .5) ;
    padding: 0 ;
  }
}
</style>
